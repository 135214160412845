// call library that helps us make http requests to external resources
import axios from "axios";

import React, { useEffect, useState } from "react";
import {
  Dropdown,
  Grid,
  Menu,
  Container,
  Icon,
  Card,
  Button,
  Modal,
  Form,
  Loader,
  Dimmer,
  Message,
} from "semantic-ui-react";

import { useHistory, Link } from "react-router-dom";
import { firestore } from "../firebase";
import { useAuth } from "../Contexts/AuthContext";
import ConfirmModal from "./ConfirmModal";

export default function Admin() {
  const history = useHistory();

  const { currentUser, logout } = useAuth();

  const [action, setAction] = useState();
  const [loadingState, setLoadingState] = useState(false);
  const [feedback, setFeedback] = useState();
  const [user, setUser] = useState([]);
  const [state, setState] = useState("Tracklogs");
  const [open, setOpen] = useState(false);
  const [tracklogId, setTracklogId] = useState();
  const [modalState, setModalState] = useState(false);

  const [imagePath, setImagePath] = useState({
    image: "",
  });

  const [tracklog, setTracklog] = useState({
    grid: "",
    key: "",
    value: "",
    text: "",
  });

  const handleItemClick = (event) => setState(event);

  const handleChange = (event) => {
    setTracklog({ ...tracklog, [event.target.name]: event.target.value });
  };

  const handleImagePath = (event) => {
    setImagePath({ ...imagePath, [event.target.name]: event.target.value });
  };

  const handleDelete = (subgrid) => {
    setModalState(true);
    setAction("Delete");
    setTracklogId(subgrid);
  };

  const handleUpdate = (subgrid) => {
    setModalState(true);
    setAction("Update");
    setTracklogId(subgrid);
  };

  const handleLogout = async () => {
    try {
      await logout();
      history.push("/login");
    } catch {}
  };

  const handleSubmitImagePath = (event) => {
    event.preventDefault();

    setLoadingState(true);

    axios
      .post("upload_to_bucket", imagePath)
      .then((response) => {
        console.log(response.data);
        setLoadingState(false);
        setImagePath({
          image: "",
        });
        setFeedback("Success");
      })
      .catch(() => {
        setLoadingState(false);
        setFeedback("Failed");
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const { grid, subgrid } = tracklog;
    firestore
      .collection("tracklogs")
      .add({
        grid: grid,
        key: subgrid,
        value: subgrid,
        text: subgrid,
      })
      .then(() => {
        setTracklog({ grid: "", key: "", value: "", text: "" });
        setOpen(false);
      });
  };

  useEffect(() => {
    firestore
      .collection("tracklogs")
      .orderBy("text", "asc")
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        setUser(data);
      });
  }, [user]);

  return (
    <>
      <Menu stackable attached="bottom">
        <Menu.Item>
          <Icon name="setting" />
          Admin Page
        </Menu.Item>
        <Menu.Item position="right">
          <Dropdown
            item
            simple
            direction="left"
            trigger={
              <div>
                <Icon name="user" />
                {currentUser.email}
              </div>
            }
          >
            <Dropdown.Menu>
              <Link to="/">
                <Dropdown.Item>
                  <Icon name="map" />
                  Street Viewer
                </Dropdown.Item>
              </Link>
              <Dropdown.Item onClick={handleLogout}>
                <Icon name="sign out" />
                Log Out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Item>
      </Menu>

      <Container fluid>
        <Grid stackable>
          <Grid.Column width={4}>
            <Menu pointing secondary vertical>
              <Menu.Item
                name="Images"
                active={state === "Images"}
                onClick={(e, { name }) => handleItemClick(name)}
              >
                Images
              </Menu.Item>
              <Menu.Item
                name="Tracklogs"
                active={state === "Tracklogs"}
                onClick={(e, { name }) => handleItemClick(name)}
              >
                Tracklogs
              </Menu.Item>
            </Menu>
          </Grid.Column>

          <Grid.Column stretched width={12} style={{ paddingLeft: "0" }}>
            <Dimmer inverted active={loadingState}>
              <Loader size="large">Uploading...</Loader>
            </Dimmer>
            {state === "Images" ? (
              <Grid container>
                <Grid.Column width={16}>
                  {feedback === "Success" ? (
                    <Message
                      color="green"
                      icon="check"
                      header="Image Uploaded Sucessfully"
                    />
                  ) : feedback === "Failed" ? (
                    <Message
                      color="red"
                      icon="close"
                      header="Image Failed to Upload"
                    />
                  ) : (
                    ""
                  )}
                  <Card fluid>
                    <Card.Content>
                      <Card.Description>
                        <Form onSubmit={handleSubmitImagePath}>
                          <Form.Field>
                            <label>Images Path</label>
                            <input
                              name="image"
                              value={imagePath.image}
                              onChange={handleImagePath}
                            />
                          </Form.Field>
                          <Button fluid color="green" type="submit">
                            Submit
                          </Button>
                        </Form>
                      </Card.Description>
                    </Card.Content>
                  </Card>
                </Grid.Column>
              </Grid>
            ) : state === "Tracklogs" ? (
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <Button onClick={() => setOpen(true)}>
                      <Icon name="add" />
                      Add Tracklog
                    </Button>
                  </Grid.Column>
                </Grid.Row>
                {user.map((item) => {
                  return (
                    <Grid.Column computer={3} tablet={8} mobile={10}>
                      <Card>
                        <Card.Content>
                          <Card.Header>Grid {item.grid}</Card.Header>
                          <Card.Description>{item.text}</Card.Description>
                        </Card.Content>
                        <Card.Content extra>
                          <div className="ui two buttons">
                            <Button
                              basic
                              color="blue"
                              onClick={() => handleUpdate(item.text)}
                            >
                              <Icon name="edit" />
                            </Button>
                            <Button
                              basic
                              color="red"
                              onClick={() => {
                                handleDelete(item.text);
                              }}
                            >
                              <Icon name="trash" />
                            </Button>
                          </div>
                        </Card.Content>
                      </Card>
                    </Grid.Column>
                  );
                })}
              </Grid>
            ) : (
              "Admin Pages"
            )}
          </Grid.Column>
        </Grid>
      </Container>

      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
      >
        <Modal.Header>Add Tracklog</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form onSubmit={handleSubmit}>
              <Form.Field>
                <label>Grid</label>
                <input
                  name="grid"
                  value={tracklog.grid}
                  onChange={handleChange}
                />
              </Form.Field>
              <Form.Field>
                <label>Subgrid</label>
                <input
                  name="subgrid"
                  value={tracklog.subgrid}
                  onChange={handleChange}
                />
              </Form.Field>
              <Button fluid color="green" type="submit">
                Submit
              </Button>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>

      <ConfirmModal
        action={action}
        item={tracklogId}
        visible={modalState}
        hide={() => setModalState(false)}
      />
    </>
  );
}

import React, { createContext, useContext, useEffect, useState } from "react";
import { auth, firestore } from "../firebase";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  const signup = (email, password) => { 
    return auth.createUserWithEmailAndPassword(email, password).then(() => {
      firestore
        .collection("users")
        .doc(auth.currentUser.uid)
        .set({
          email: email,
        })
        .catch((error) => {
          console.log(
            "Something went wrong with added user to firestore: ",
            error
          );
        });
    });
  };

  const login = (email, password) => {
    return auth.signInWithEmailAndPassword(email, password);
  };

  const logout = () => {
    return auth.signOut();
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    signup,
    login,
    logout,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

export default AuthContext;

import React, { useState } from "react";
import { firestore } from "../firebase";
import { Button, Modal, Form } from "semantic-ui-react";

export default function ConfirmModal({ action, item, visible, hide }) {
  const [items, setItems] = useState({});

  const handleChange = (event) => {
    setItems({ ...items, [event.target.name]: event.target.value });
  };

  const deletItem = () => {
    firestore
      .collection("tracklogs")
      .where("text", "==", item)
      .get()
      .then((querySnapshot) => {
        querySnapshot.docs[0].ref.delete();
      });
  };

  const updateItem = (event) => {
    event.preventDefault();
    firestore
      .collection("tracklogs")
      .where("text", "==", item)
      .get()
      .then((querySnapshot) => {
        querySnapshot.docs[0].ref.update({
          grid: items.grid,
          subgrid: items.subgrid,
        });
      });
  };

  return (
    <Modal size="mini" onClose={hide} onOpen={visible} open={visible}>
      <Modal.Header>
        {action} {item}
      </Modal.Header>
      {action === "Delete" ? (
        <Modal.Actions>
          <Button color="green" onClick={hide}>
            No
          </Button>
          <span onClick={hide}>
            <Button color="red" onClick={deletItem}>
              Yes
            </Button>
          </span>
        </Modal.Actions>
      ) : action === "Update" ? (
        <Modal.Content>
          <Modal.Description>
            <Form>
              <Form.Field>
                <label>Grid</label>
                <input name="grid" value={item.grid} onChange={handleChange} />
              </Form.Field>
              <Form.Field>
                <label>Subgrid</label>
                <input
                  name="subgrid"
                  value={item.subgrid}
                  onChange={handleChange}
                />
              </Form.Field>
              <span onClick={hide}>
              <Button fluid color="green" type="submit" onClick={updateItem}>
                Update
              </Button>
              </span>
            </Form>
          </Modal.Description>
        </Modal.Content>
      ) : (
        ""
      )}
    </Modal>
  );
}

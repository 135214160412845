import React, { useRef, useState } from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Message, 
} from "semantic-ui-react";
import { useHistory } from "react-router-dom";

import { useAuth } from "../Contexts/AuthContext";

export default function LoginForm() {
  const emailRef = useRef();
  const passwordRef = useRef();

  const { login } = useAuth();

  const [error, setError] = useState("");
  const [loadingState, setLoadingState] = useState(false);

  const history = useHistory();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setError("");
      setLoadingState(true);
      await login(emailRef.current.value, passwordRef.current.value);
      history.push("/");
    } catch {
      setError("Failed to sign in");
    }

    setLoadingState(false);
  };

  return (
    <div>
      <Grid
        textAlign="center"
        style={{ height: "100vh" }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as="h2" color="blue" textAlign="center">
            Log In
          </Header>
          <Form className="attached fluid segment" onSubmit={handleSubmit}>
            {error && <Message negative>{error}</Message>}
            <Form.Field>
              <input type="email" ref={emailRef} placeholder="Email" required />
            </Form.Field>
            <Form.Field>
              <input
                type="password"
                ref={passwordRef}
                placeholder="Password"
                minLength="6"
                required
              />
            </Form.Field>
            <Button
              inverted
              loading={loadingState}
              color="blue"
              fluid
              size="large"
            >
              Log In
            </Button>
          </Form>
        </Grid.Column>
      </Grid>
    </div>
  );
}

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import MapScreen from "../Screens/Map"; 
import Login from "./Login";
import Admin from "./Admin";
import PrivateRoute from './PrivateRoute'
import { AuthProvider } from "../Contexts/AuthContext";
import '../App.css'

function App() { 
  return (
    <div>
      <Router>
        <AuthProvider>
          <Switch>
            <Route path="/login" component={Login} />
            <PrivateRoute exact path="/" component={MapScreen} />
            <PrivateRoute path="/admin" component={Admin} />
            
          </Switch>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
